"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ALLOW_BANKING_DETAILS_VERIFICATION = exports.CUT_OFF_DATES = exports.ALLOWED_FUTURE_DATED_MONTHS = exports.PAGE_ACTION_TYPES = exports.multiplyCover = exports.PARTNER = exports.COVER_MULTIPLIER = exports.INTERACTION_ID_HEADER_KEY = void 0;
var INTERACTION_ID_HEADER_KEY = 'Simply-Interaction-Id';
exports.INTERACTION_ID_HEADER_KEY = INTERACTION_ID_HEADER_KEY;
var COVER_MULTIPLIER = 100;
exports.COVER_MULTIPLIER = COVER_MULTIPLIER;
var PARTNER = 'simply';
exports.PARTNER = PARTNER;

var multiplyCover = function multiplyCover(cover) {
  return cover * COVER_MULTIPLIER;
};

exports.multiplyCover = multiplyCover;
var PAGE_ACTION_TYPES = {
  PAGE: 'PAGE',
  ROUTE: 'ROUTE'
};
exports.PAGE_ACTION_TYPES = PAGE_ACTION_TYPES;
var ALLOWED_FUTURE_DATED_MONTHS = 2;
exports.ALLOWED_FUTURE_DATED_MONTHS = ALLOWED_FUTURE_DATED_MONTHS;
var CUT_OFF_DATES = {
  '2020': {
    JAN: 23,
    FEB: 20,
    MAR: 19,
    APR: 23,
    MAY: 21,
    JUN: 18,
    JUL: 23,
    AUG: 20,
    SEP: 19,
    OCT: 24,
    NOV: 14,
    DEC: 5
  },
  '2021': {
    JAN: 22,
    FEB: 19,
    MAR: 19,
    APR: 16,
    MAY: 21,
    JUN: 16,
    JUL: 16,
    AUG: 20,
    SEP: 17,
    OCT: 15,
    NOV: 12,
    DEC: 3
  },
  '2022': {
    JAN: 21,
    FEB: 18,
    MAR: 25,
    APR: 22,
    MAY: 20,
    JUN: 24,
    JUL: 22,
    AUG: 26,
    SEP: 23,
    OCT: 14,
    NOV: 11,
    DEC: 2
  },
  '2023': {
    JAN: 20,
    FEB: 17,
    MAR: 17,
    APR: 21,
    MAY: 19,
    JUN: 23,
    JUL: 21,
    AUG: 25,
    SEP: 22,
    OCT: 13,
    NOV: 3,
    DEC: 1
  },
  '2024': {
    JAN: 19,
    FEB: 21,
    MAR: 22,
    APR: 19,
    MAY: 24,
    JUN: 21,
    JUL: 26,
    AUG: 23,
    SEP: 20,
    OCT: 18,
    NOV: 15,
    DEC: 6
  },
  '2025': {
    JAN: 24,
    FEB: 21,
    MAR: 21,
    APR: 18,
    MAY: 23,
    JUN: 20,
    JUL: 25,
    AUG: 22,
    SEP: 19,
    OCT: 17,
    NOV: 14,
    DEC: 5
  }
};
exports.CUT_OFF_DATES = CUT_OFF_DATES;
var ALLOW_BANKING_DETAILS_VERIFICATION = true;
exports.ALLOW_BANKING_DETAILS_VERIFICATION = ALLOW_BANKING_DETAILS_VERIFICATION;
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AcceptCheckboxQuestion from '../questions/AcceptCheckboxQuestion';
import { toCurrency } from '../common/numberFormats';
import { ShadowedContainer } from '@simply-fin-services/astronomix3';
import styled from 'styled-components';

const Tooltip = styled.div`
  line-height: 19px;
`;
export default class RopSelector extends PureComponent {
  render() {
    const { withRop, rate, amount, toggle, ropReturn,ropTotalPremium } = this.props;
    const t = () => toggle(!withRop);
    return (
      <ShadowedContainer>
        <AcceptCheckboxQuestion
          onChange={t}
          checked={withRop}
          text="Add Cash Back benefit"
          labelIsBold
        />
        <Tooltip className="small">
          For <strong>{toCurrency(amount)}</strong> extra pm, you will receive{' '}
          <strong>{rate}%</strong> of your total premium back every 5 years. So,
          in the first 5 years you’ll pay an extra{' '}
          <strong>{toCurrency(ropTotalPremium)}</strong> and get{' '}
          <strong>{toCurrency(ropReturn)}</strong> back.
        </Tooltip>
      </ShadowedContainer>
    );
  }
}

RopSelector.propTypes = {
  withRop: PropTypes.bool.isRequired,
  rate: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  ropReturn: PropTypes.number.isRequired,
  ropTotalPremium: PropTypes.number.isRequired,
  toggle: PropTypes.func.isRequired,
};
